<template>
  <v-layout column justify-center fill-height class="text-input-mission">
    <!-- Mission question -->
    <v-flex d-flex class="mission-instructions">
      <ResizableText :message="instructions" />
    </v-flex>
    <v-flex shrink>
      <TextField
        class="mission-text-feild"
        :placeholder="placeholder"
        :status="status"
        :show="showTextField"
        :buttonText="'SUBMIT'"
        @onSubmit="onSubmit"
        :maxTextLength="maxTextLength"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import { GameMixin } from "@/mixins"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import TextField from "./TextField.vue"
export default {
  name: "TextInput",
  mixins: [GameMixin],
  components: {
    ResizableText,
    TextField
  },
  props: {
    mode: String,
    mission: Object,
    maxTextLength: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      waitingText: "Waiting on Scribe...",
      games: null
    }
  },
  computed: {
    ...mapGetters("auth", ["isAudit", "isSpectator", "isHost", "clientID"]),
    ...mapGetters([
      "missionFail",
      "isScribe",
      "missionSuccess",
      "missionCompleted",
      "missionPlayType",
      "missionAnswers",
      "team",
      "game"
    ]),
    gamesArray() {
      return Object.values(this.games)
    },
    isTeamMission() {
      return this.missionPlayType === "team"
    },
    instructions() {
      return this.mission.instructions
    },
    correct() {
      return this.mission.answer.split(",")[0]
    },
    behavior() {
      return this.mission.behavior
    },
    showTextField() {
      return this.mode !== "explain" ? "show" : ""
    },
    canPlay() {
      const { mode } = this
      return (
        (mode === "play" || mode === "huddle" || mode === "social") &&
        !this.isAudit &&
        !this.isSpectator
      )
    },
    status() {
      const {
        missionSuccess,
        missionFail,
        isHost,
        missionCompleted,
        isScribe,
        canPlay,
        isAudit,
        isSpectator
      } = this
      if (missionSuccess) {
        return "correct"
      } else if (missionFail) {
        return "wrong"
      } else if (!isHost && !missionCompleted && isScribe && canPlay) {
        return "active" // + lineThroughClass
      } else if (isAudit || isSpectator) {
        return "You are auditing"
      } else {
        return ""
      }
    },
    lineThroughClass() {
      return this.missionFail ||
        (this.missionAnswersLength > 0 && !this.missionSuccess)
        ? " line-through"
        : ""
    },
    missionAnswersLength() {
      return this.missionAnswers.length
    },
    placeholder() {
      if (this.isHost || this.game.showAnswers) {
        // always show the asnwer to the host
        return this.correct
      }

      if (this.missionCompleted) {
        return this.correct
      }

      if (this.isAudit || this.isSpectator) {
        // if scrive and ready to go
        return "You are auditing.  Answer: " + this.correct
      }

      if (!this.isScribe) {
        return "Waiting for scribe to answer..."
      }

      if (
        this.isTeamMission &&
        this.missionAnswersLength &&
        !this.missionCompleted
      ) {
        // set the placeholder message for a non scribe
        // to the latest enetered by a scribe
        const answer = this.missionAnswers[this.missionAnswersLength - 1]
        return typeof answer === "string" ? answer : ""
      }

      if (this.missionSuccess) {
        if (this.behavior == "Freeform") {
          return "Received!"
        } else {
          return "Correct!"
        }
      }

      if (this.missionFail) {
        return this.correct
      }

      if (this.isScribe && this.canPlay) {
        // if scrive and ready to go
        return "Enter here..."
      }
      // scribe but not ready to go
      return "Wait..."
    }
  },
  methods: {
    async onSubmit(data) {
      if (!this.isScribe || this.missionCompleted)
        return console.log("No rights for submit")
      if (!data) return console.log("Won't submit an empty string")
      this.answer = data
      await this.checkAnswer()
      this.answer = ""
    }
  }
}
</script>

<style lang="scss">
.text-input-mission {
  .mission-instructions {
    padding: 25px 10px 5px;
  }
  .mission-text-feild {
    position: relative;
    margin-left: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
}
</style>
